import img1 from './img/img1.png'
import img2 from './img/img2.png'
import img3 from './img/img3.jpg'

export const book = [
  {
    author: 'Melania Trump',
    title: 'Melania',
    image: img2,
    id: 1,
  },
  {
    author: 'Freida McFadden',
    title: "The Housemaid's Wedding",
    image: img1,
    id: 2,
  },
  {
    author: 'Jordan Moore',
    title: 'Interesting facts for curious minds',
    image: img3,
    id: 3,
  },
]

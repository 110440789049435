const Book = ({ author, title, image, number }) => {
  // const { author, title, image } = props.book
  return (
    <article className="book">
      <span className="number">{`# ${number + 1}`}</span>
      <img src={image} alt={title} />
      <h2>{title}</h2>
      <h4 className="author">{author}</h4>
    </article>
  )
}

export default Book

import React from 'react'
import ReactDom from 'react-dom/client'

import './index.css'
import { book } from './books'
import Book from './Book'

function BookList() {
  //return React.createElement('div', {}, 'Hello World')
  // return React.createElement(
  //   'div',
  //   {},
  //   React.createElement('h1', {}, 'Hello Swhavy')
  // )

  return (
    <>
      <div className="heading">Amazon Best selling Books</div>
      <section className="booklist">
        {book.map((book, index) => {
          // {
          //   console.log(book)
          // }
          return <Book {...book} key={book.id} number={index} />
        })}
      </section>
    </>
  )
}

const root = ReactDom.createRoot(document.getElementById('root'))

root.render(<BookList />)
